import { render, staticRenderFns } from "./nosotros.vue?vue&type=template&id=890147be&scoped=true"
import script from "./nosotros.vue?vue&type=script&lang=js"
export * from "./nosotros.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "890147be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMain: require('/builds/marketing/abstracta.academy-website/components/HeaderMain.vue').default})
