import { render, staticRenderFns } from "./CourseCard.vue?vue&type=template&id=1171a362&scoped=true"
import script from "./CourseCard.vue?vue&type=script&lang=js"
export * from "./CourseCard.vue?vue&type=script&lang=js"
import style0 from "./CourseCard.vue?vue&type=style&index=0&id=1171a362&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1171a362",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkIcon: require('/builds/marketing/abstracta.academy-website/components/LinkIcon.vue').default})
