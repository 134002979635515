//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BBreadcrumb, BCard, BCardBody } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';
import { getCourseDurationText } from '~/utils/course';
import { loadCourse } from '~/data/courses/index';

export default {
  name: "Curso",
  layout: "default",
  components: { BBreadcrumb, BCard, BCardBody, Icon },
  async asyncData(context) {
    const cursoId = context.params.curso;
    try {
      const courseInfo = await loadCourse(cursoId)
      return { courseInfo: courseInfo }
    }
    catch (e) {
      const path = context.localePath("404");
      context.redirect(path);
    }
  },
  head() {
    if (!this.courseInfo) return {};
    const { title, description, keywords, imageUrl } = this.courseInfo.metaTags;
    return {
      title: title,
      meta: [
        { hid: 'description', name: 'description', content: description },
        { hid: 'keywords', name: 'description', content: keywords },
        { hid: 'og:image', property: 'og:image', content: imageUrl },
      ],
    }
  },
  data() {
    return {
      floatingCardBottomLimit: Number.MAX_VALUE,
      pricePesos: Number,
      paymentMethod: ["paypal"]
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: 'Cursos',
          to: this.localePath("/cursos")
        },
        {
          text: this.courseInfo.category,
          to: { path: this.localePath(`/cursos?category=${this.courseInfo.category}`), hash: "#cursos" }
        },
        {
          text: this.courseInfo.name,
          active: true
        }
      ]
    },
  },
  methods: {
    calculateFloatingCardLimit() {
      if (this.$refs['card-specialization']) {
        const { $el: el } = this.$refs['card-specialization'];
        const { top, height } = el.getBoundingClientRect();
        this.floatingCardBottomLimit = document.documentElement.scrollTop + top + height;
      } else if (this.$refs['card-modules']) {
        const { $el: el } = this.$refs['card-modules'];
        const { top, height } = el.getBoundingClientRect();
        this.floatingCardBottomLimit = document.documentElement.scrollTop + top + height;
      }
    },

    getCourseDurationText,
    onBtnClick() {
      let payment = document.querySelectorAll('input[type="checkbox"]:checked');
      sessionStorage.setItem('coursePaymentMethod', payment[0].value);

      if (payment[0].value == "paypal") {
        this.$router.push('/es/concretar-compra/');
      }

      this.$emit("btnClick");
    },
  },
  async mounted() {

    //
    this.$nextTick(() => {
      window.addEventListener("resize", this.calculateFloatingCardLimit);
      this.calculateFloatingCardLimit();
    });

    let dolarPrice = window.sessionStorage.getItem('dolarPrice');
    if (dolarPrice) dolarPrice = parseFloat(dolarPrice);
    else {
      /*const res = await this.$api.cotizacion.getCotizacion({ monedas: [2225] })
      dolarPrice = res[0].TCV;*/
      window.sessionStorage.setItem('dolarPrice', dolarPrice);
    }
    // this.pricePesos = parseFloat((this.courseInfo.priceUSD * dolarPrice) * 1.22).toFixed(2); //
    //this.pricePesos = Math.round((this.courseInfo.priceUSD * dolarPrice) * 1.22);
    this.pricePesos = this.courseInfo.pricePesos;
    sessionStorage.setItem('courseName', this.courseInfo.name);
    sessionStorage.setItem('courseBadgeImg', this.courseInfo.badgeImg);
    sessionStorage.setItem('coursePriceUSD', this.courseInfo.priceUSD);
    sessionStorage.setItem('coursePricePesos', this.pricePesos);
    sessionStorage.setItem('courseTalentLink', this.courseInfo.talentLink);

    this.paymentMethod = sessionStorage.coursePaymentMethod;

  },
  watch: {
  }
};
